import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Particles from 'react-particles-js';

import Layout from '../components/Layout';
import Head from '../components/Head';
import pentagonParams from '../lib/particles/pentagon.json';

const NotFound = () => (
  <Layout>
    <Head title='Page not Found' />
    <div className='not-found'>
      <Particles params={pentagonParams} />
      <div className='not-found__text center-xs'>
        <h1 className='mb-2x'>Error 404, page not found</h1>
        <h3>
          Please go to{' '}
          <AniLink className='text--underline' fade to='/'>
            home
          </AniLink>{' '}
        </h3>
      </div>
    </div>
  </Layout>
);

export default NotFound;
